<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <parte-subir-form
          v-if="dbReady"
          :id="routeParams.idparte_trabajo"
          :permission-tipo-cierre-subir-parte="permissionTipoCierreSubirParte"
          :usuario-idtecnico="usuarioIdtecnico"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteSubirForm from './components/ParteSubirForm'
import Data from './ParteSubirData'
import { doSync } from '@/sync/upload'
import { get } from 'vuex-pathify'
import { TIPO_CIERRE_SUBIR_PARTE } from '@/utils/consts'

export default {
  components: { ParteSubirForm },
  mixins: [formPageMixin],
  data () {
    return {
      permissionTipoCierreSubirParte: {},
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = `Subir parte Nº${this.routeParams.idparte_trabajo}`
      // Armo el objeto de permisos para pasarlo al form
      for (const item in TIPO_CIERRE_SUBIR_PARTE.permisos) {
        const permValue = TIPO_CIERRE_SUBIR_PARTE.permisos[item];
        this.permissionTipoCierreSubirParte[item] = permValue ? this.hasPerm(permValue) : true;
      }
    },
    async submitForm () {
      this.$loading.showManual('Subiendo parte')
      try {
        const parteTrabajo = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
        if (this.formData.cambiar_estado && !this.formData.idestado_destino) {
          throw new Error('No se puede establecer un estado de destino')
        } else {
          const respSubir = await Data.subir(
            this, this.routeParams.idparte_trabajo, this.formData, this.usuarioIdtecnico, parteTrabajo.idsistema
          )
          if (this.formData.tipo_cierre === TIPO_CIERRE_SUBIR_PARTE.tipos.reasignarParte) {
            this.$alert.showSnackbarSuccess(
              `Se ha creado el parte de trabajo Nº${respSubir.idparte_trabajo}`
            )
          }
        }
      } finally {
        this.$loading.hide()
      }
      await doSync(this.$offline, this.$api, this.$loading, this.$alert)
      // redirección al index porque el parte ya no existe localmente (y puede venir de ParteResumenClienteView -> 404)
      this.$appRouter.replace({ name: 'index' })
    },
  },
}
</script>
